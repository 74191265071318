/* @import url("https://fonts.googleapis.com/css2?family=VT323&display=swap"); */
@font-face {
  font-family: "Fixed-6x13";
  src: url(/static/fonts/fixed-6x13.otf);
}
@font-face {
  font-family: "Fixed-6x13b";
  src: url(/static/fonts/fixed-6x13b.otf);
}
@font-face {
  font-family: "VT323";
  src: url(/static/fonts/VT323-Regular.otf);
}
@font-face {
  font-family: "VT100";
  src: url(/static/fonts/VT100-Regular.otf);
}

:root {
  --colour-background: #0a0a0a;
  --colour-black: #000000;
  --colour-cyan: #90ffff;
  --colour-cyan-dark: #549c9c;
  --colour-cyan-muted: #506161;
  --colour-cyan-300: #cdffff;
  --colour-cyan-500: #90ffff;
  --colour-cyan-700: #549c9c;
  --colour-vt100-text: #8ce3ff;
  --colour-vt100-text-alt: #81dff6;
  --colour-vt100-text-dark: #6db3b9;
  --colour-vt100-text-bg: #2e2a24;
  --colour-vt100-text-bg-dark: #211f1b;
  --colour-body: hsl(352, 23%, 3%);
  --colour-bg: hsl(352, 47%, 12.55%);
  --colour-bg: hsl(352, 47%, 13%);
  --colour-fg: hsl(167, 100%, 68%);
  --colour-fg-light: hsl(167, 100%, 85%);
  --colour-fg-light-a: hsl(167, 85%, 92%);
  --colour-fg-dark: hsl(167, 100%, 38%);
  --terminal-font-size: 12px;
  --terminal-line-height: 10px;
  --terminal-char-width: 5px;
  --terminal-margin-v: calc(var(--terminal-line-height) / 2);
  --terminal-margin-h: var(--terminal-char-width);
  --terminal-width: calc(800px - (var(--terminal-margin-h) * 2));
  --terminal-height: calc(120px - (var(--terminal-margin-v) * 2));
  --terminal-cols: calc(var(--terminal-width) / var(--terminal-char-width));
  --terminal-lines: calc(var(--terminal-height) / var(--terminal-line-height));
  --cursor-blink-rate: 450ms;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html,
body {
  font-family: "VT323", "VT100", "Fixed-6x13", monospace;
  margin: 0;
  padding: 0;
  color: var(--colour-fg);
  font-size: var(--terminal-font-size);
  line-height: var(--terminal-line-height);
  letter-spacing: 0px;
  background-color: var(--colour-bg);
}

body {
  display: grid;
}

#app {
  overflow: hidden;
  text-transform: uppercase;
  margin: 0 auto;
  padding: var(--terminal-margin-v) var(--terminal-margin-h);
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: var(--colour-fg-light);
}
#app * {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
#terminal {
  overflow: hidden;
  background-color: var(--colour-bg);
  border-radius: 3px;
  text-shadow: 0px 0px 1.5px;
  /* box-shadow: 0 0 200px 20px rgba(80, 97, 97, 0.4), 0 0 30px 10px rgba(80, 97, 97, 0.1) inset; */
  width: 100%;
  height: 100%;
  width: var(--terminal-width);
  height: var(--terminal-height);
}
#buffer span {
  display: block;
  float: left;
  width: var(--terminal-char-width);
}

canvas {
  display: block;
  margin: auto;
}

#cursor {
  display: block;
  overflow: visible;
  z-index: 10;
  width: var(--terminal-char-width);
  height: var(--terminal-line-height);
  margin-top: 0;
  margin-left: 0;
  background: var(--colour-fg);
  border-radius: 0.5px;
  box-shadow: 0px 0px 2px, 0 0 20px rgba(255, 255, 255, 0.13);
  -webkit-animation: blink 600ms cubic-bezier(1, 0, 0, 1) infinite;
  animation: blink 600ms cubic-bezier(1, 0, 0, 1) infinite;
}

@-webkit-keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  48%,
  52% {
    opacity: 0.05;
  }
  50% {
    opacity: 0;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  48%,
  52% {
    opacity: 0.05;
  }
  50% {
    opacity: 0;
  }
}

.scanlines {
  -webkit-animation: textShadow 1.6s infinite;
  animation: textShadow 1.6s infinite;
}
.scanlines:before {
  background: 
    linear-gradient(hsla(0,6%,7%,0) 50%, rgba(0,0,0,.25) 0),
    linear-gradient(90deg, rgba(255,0,0,.06), rgba(0,255,0,.02), rgba(0,0,255,.06));
  background-size: 100% 1.5px, 3px 100%;
}
.scanlines:after, .scanlines:before {
  bottom: 0;
  content: " ";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
