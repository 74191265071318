@font-face {
  font-family: Fixed-6x13;
  src: url("fixed-6x13.55edc386.otf");
}

@font-face {
  font-family: Fixed-6x13b;
  src: url("fixed-6x13b.188f68a3.otf");
}

@font-face {
  font-family: VT323;
  src: url("VT323-Regular.04bd137c.otf");
}

@font-face {
  font-family: VT100;
  src: url("VT100-Regular.28b294fd.otf");
}

:root {
  --colour-background: #0a0a0a;
  --colour-black: #000;
  --colour-cyan: #90ffff;
  --colour-cyan-dark: #549c9c;
  --colour-cyan-muted: #506161;
  --colour-cyan-300: #cdffff;
  --colour-cyan-500: #90ffff;
  --colour-cyan-700: #549c9c;
  --colour-vt100-text: #8ce3ff;
  --colour-vt100-text-alt: #81dff6;
  --colour-vt100-text-dark: #6db3b9;
  --colour-vt100-text-bg: #2e2a24;
  --colour-vt100-text-bg-dark: #211f1b;
  --colour-body: #090606;
  --colour-bg: #2f1115;
  --colour-bg: #311216;
  --colour-fg: #5cffdc;
  --colour-fg-light: #b3ffee;
  --colour-fg-light-a: #d9fcf4;
  --colour-fg-dark: #00c298;
  --terminal-font-size: 12px;
  --terminal-line-height: 10px;
  --terminal-char-width: 5px;
  --terminal-margin-v: calc(var(--terminal-line-height) / 2);
  --terminal-margin-h: var(--terminal-char-width);
  --terminal-width: calc(800px - (var(--terminal-margin-h) * 2) );
  --terminal-height: calc(120px - (var(--terminal-margin-v) * 2) );
  --terminal-cols: calc(var(--terminal-width) / var(--terminal-char-width));
  --terminal-lines: calc(var(--terminal-height) / var(--terminal-line-height));
  --cursor-blink-rate: 450ms;
}

*, :after, :before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html, body {
  color: var(--colour-fg);
  font-family: VT323, VT100, Fixed-6x13, monospace;
  font-size: var(--terminal-font-size);
  line-height: var(--terminal-line-height);
  letter-spacing: 0;
  background-color: var(--colour-bg);
  margin: 0;
  padding: 0;
}

body {
  display: grid;
}

#app {
  text-transform: uppercase;
  padding: var(--terminal-margin-v) var(--terminal-margin-h);
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: var(--colour-fg-light);
  margin: 0 auto;
  overflow: hidden;
}

#app * {
  text-transform: uppercase;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin: 0;
  padding: 0;
}

#terminal {
  background-color: var(--colour-bg);
  text-shadow: 0 0 1.5px;
  width: 100%;
  height: 100%;
  width: var(--terminal-width);
  height: var(--terminal-height);
  border-radius: 3px;
  overflow: hidden;
}

#buffer span {
  float: left;
  width: var(--terminal-char-width);
  display: block;
}

canvas {
  margin: auto;
  display: block;
}

#cursor {
  z-index: 10;
  width: var(--terminal-char-width);
  height: var(--terminal-line-height);
  background: var(--colour-fg);
  border-radius: .5px;
  margin-top: 0;
  margin-left: 0;
  animation: blink .6s cubic-bezier(1, 0, 0, 1) infinite;
  display: block;
  overflow: visible;
  box-shadow: 0 0 2px, 0 0 20px #ffffff21;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }

  48%, 52% {
    opacity: .05;
  }

  50% {
    opacity: 0;
  }
}

.scanlines {
  animation: textShadow 1.6s infinite;
}

.scanlines:before {
  background: linear-gradient(#13111100 50%, #00000040 0) 0 0 / 100% 1.5px, linear-gradient(90deg, #ff00000f, #00ff0005, #0000ff0f) 0 0 / 3px 100%;
}

.scanlines:after, .scanlines:before {
  content: " ";
  pointer-events: none;
  z-index: 10;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/*# sourceMappingURL=index.970b4663.css.map */
